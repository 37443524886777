<template>
  <router-link
    :to="{ name: 'Alerts', params: { rulesTargetted: cardConfig.rules } }"
    tag="span"
    style="cursor: pointer"
  >
    <v-hover>
      <template v-slot:default="{ hover }">
        <div
          :class="`elevation-${hover ? 4 : 0}`"
          class="transition-swing rounded"
        >
          <v-alert
            :color="cardConfig.color"
            :icon="`mdi-${cardConfig.icon}`"
            prominent
            elevation="2"
            dark
          >
            <strong>{{ counter }}</strong> {{ $tc("unseen.alert", counter) }}
            <strong>{{ $t(`unseen.${cardConfig.name}`) | capitalize }}</strong>
            {{ $tc("unseen.unseen", counter) }}.
          </v-alert>
        </div>
      </template>
    </v-hover>
  </router-link>
</template>

<script>
export default {
  props: {
    cardConfig: {
      type: Object,
      required: true,
    },
    counter: {
      type: Number,
      required: true,
    },
  },
};
</script>
